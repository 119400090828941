#types ion-col{
    background-color: #93cdc4;
    border: solid 1px #fff;
    color: #fff;
    text-align: center;
  }

.type{
    padding: 8px;
    display: block;
}  