
.rightInput{
    text-align: right;
}


.node_card{
    border: 1px solid #ccc;
    box-shadow: none;
    /* z-index: 0; */
  }